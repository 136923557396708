import moment from "moment";
import "moment/locale/ko";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useStores } from "../hooks/useStores";
import { WebSocketBackgroundJobEnded, WebSocketBackgroundJobStatus } from "../store/WebSocketStore";
import { useNotification } from "../hooks/useNotification";
import styled from "styled-components";
import { useIntl } from "react-intl";

const SORT_BY_STATUS = ["error", "in_progress", "waiting", "canceled", "done"];

const BackgroundJobPanel: React.FC = () => {
    const [minimize, setMinimize] = useState<boolean>(true);
    const [backgroundJobStatus, setBackgroundJobStatus] = useState<WebSocketBackgroundJobStatus[]>([]);
    const { accountStore, webSocketStore } = useStores();
    const { agentId } = useParams();
    const intl = useIntl();
    const notification = useNotification();

    const STYLE_BY_STATUS = {
        in_progress: { label: "진행중", color: "#1a820e" },
        waiting: { label: "대기중", color: "#39afd1" },
        canceled: { label: "취소됨", color: "#616161" },
        error: { label: "오류", color: "#a60c0c" },
        done: { label: "완료됨", color: "#242424" },
    };

    const LABEL_BY_TYPE = {
        agent: "에이전트",
        intent: "인텐트",
        typo: "오타교정",
        entity: "엔티티 업로드",
        synonym: "유의어",
        selector: "셀렉터",
        autocomplete: "자동완성",
        testing: "대량 테스트",
        training: "Sandbox 학습",
        deploy: "서비스 반영",
        entity_export: "엔티티 다운로드",
        entity_delete: "엔티티 삭제",
        entity_bulk_insert: "벌크 엔티티 삽입",
        entity_bulk_apply: "벌크 엔티티 적용"
    };

    const receiveBackgroundJobStatus = (data: WebSocketBackgroundJobStatus[]) => {
        setBackgroundJobStatus(
            data
                .sort((prev, next) => {
                    return moment(next.created_at).diff(moment(prev.created_at));
                })
                .sort((prev, next) => {
                    return SORT_BY_STATUS.indexOf(prev.status) - SORT_BY_STATUS.indexOf(next.status);
                })
        )
    };

    const receiveBackgroundJobEnded = (job: WebSocketBackgroundJobEnded) => {
        if (job.type === "entity_bulk_insert" || job.type === "entity_bulk_apply") return;

        if (job.status === "done") {
            notification.show("success", intl.formatMessage({id: "i000336"}, {job: LABEL_BY_TYPE[job.type]}));
        } else if (job.status === "error") {
            notification.show("error", intl.formatMessage({id: "i000337"}, {job: LABEL_BY_TYPE[job.type]}));
        }
    }

    const JobActionButton: React.FC<{ job: WebSocketBackgroundJobStatus }> = ({ job }) => {
        if(["in_progress", "waiting"].includes(job.status))
            return (
                <button
                    style={{
                        fontSize: "12px",
                        border: "1px solid red",
                        borderRadius: "4px",
                        backgroundColor: "transparent",
                        color: "red"
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();

                        webSocketStore.sendCancelRequest(job);
                    }}
                >
                    취소
                </button>
            );

        return (
            <button
                style={{
                    fontSize: "12px",
                    border: "1px solid red",
                    borderRadius: "4px",
                    backgroundColor: "transparent",
                    color: "red"
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    webSocketStore.sendDeleteRequest(job);
                }}
            >
                삭제
            </button>
        );
    };

    useEffect(() => {
        webSocketStore.addEventListener("background_job_status", receiveBackgroundJobStatus);
        webSocketStore.addEventListener("background_job_ended", receiveBackgroundJobEnded);

        return () => {
            webSocketStore.removeEventListener("background_job_status", receiveBackgroundJobStatus);
            webSocketStore.removeEventListener("background_job_ended", receiveBackgroundJobEnded);
        };
        // eslint-disable-next-line
    }, [webSocketStore]);

    useEffect(() => {
        accountStore.getProfile().then(() => {
            if (accountStore.account) {
                webSocketStore.connectSocket(agentId, accountStore.account, { transports: ["websocket"] });
            }
        });

        return () => {
            accountStore.clear();
        };
    }, [accountStore, webSocketStore, agentId]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: minimize ? "180px" : "1080px",
                height: minimize ? "40px" : "720px",
                opacity: minimize ? 0.6 : 1,
                position: "fixed",
                right: "12px",
                bottom: "12px",
                backgroundColor: "white",
                border: "1px solid gray",
                zIndex: 10,
                padding: "0px",
                borderRadius: "12px",
                overflow: "hidden"
            }}
        >
            <div style={{ display: "flex", position: "relative", height: "40px", width: "100%", flexShrink: 0, borderBottom: "1px solid gray" }}>
                <div style={{ marginLeft: "12px", alignSelf: "center", fontWeight: "bold"}}>Background Jobs</div>
                <div
                    style={{
                        marginLeft: "4px",
                        alignSelf: "center",
                        color: STYLE_BY_STATUS[backgroundJobStatus[0]?.status || "done"].color
                    }}
                >
                    <BackgroundJobAnimationStyle inProgress={backgroundJobStatus[0]?.status === "in_progress"}>
                        <i className="blink mdi mdi-checkbox-blank-circle mdi-16px"/>
                    </BackgroundJobAnimationStyle>
                </div>
                <button
                    style={{
                        border: "none",
                        position: "absolute",
                        right: "0px",
                        top: "0px",
                        textDecoration: "none",
                        backgroundColor: "transparent"
                    }}
                    onClick={() => {
                        setMinimize((min) => {
                            return !min;
                        });
                    }}
                >
                    <i className={`mdi ${minimize ? "mdi-chevron-up" : "mdi-chevron-down"} mdi-24px`}/>
                </button>
            </div>
            {!minimize && (
                <div style={{ overflow: "auto" }}>
                    <table id="background-job-table" className="table table-hover table-centered mb-0">
                        <thead>
                            <tr>
                                <th style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}>작업</th>
                                <th style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}>진행도</th>
                                <th style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}>시작 시간</th>
                                <th style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}>진행 시간</th>
                                <th style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}>남은 시간</th>
                                <th style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}>처리 시간</th>
                                <th style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}>링크</th>
                                <th style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}>상태</th>
                                <th style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}/>
                            </tr>
                        </thead>
                        <tbody>
                            {backgroundJobStatus.map((job, index) => {
                                return (
                                    <tr key={`job-${index}`}>
                                        <td style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}>{LABEL_BY_TYPE[job.type]}</td>
                                        <td style={{ padding: "4px", textAlign: "center", fontSize: "12px" }}>
                                            <div style={{ borderBottom: "1px solid gray" }}>{job.count_current}</div>
                                            <div>{job.count_total}</div>
                                        </td>
                                        <td style={{ padding: "4px", textAlign: "center", fontWeight: "bold" }}>
                                            {moment.utc(job.created_at).local().format("YYYY-MM-DD HH:mm:ss")}
                                        </td>
                                        <td style={{ padding: "4px", textAlign: "center" }}>
                                            {moment.duration(job.elapsed_seconds, "seconds").humanize()}
                                        </td>
                                        <td style={{ padding: "4px", textAlign: "center" }}>
                                            {job.remain_seconds > 0 ? moment.duration(job.remain_seconds, "seconds").humanize() : '-'}
                                        </td>
                                        <td style={{ padding: "4px", textAlign: "center" }}>
                                            {job.processing_seconds > 0 ? moment.duration(job.processing_seconds,"seconds").humanize() : '-'}
                                        </td>
                                        <td style={{ padding: "4px", textAlign: "center" }}>
                                            {job.download_link && (
                                                <a target="_blank" rel="noopener noreferrer" href={job.download_link}>다운</a>
                                            )}
                                        </td>
                                        <td
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                padding: "4px",
                                                fontWeight: "bold",
                                                color: STYLE_BY_STATUS[job.status]?.color
                                            }}
                                        >
                                            <div style={{ fontSize: "12px", alignSelf: "center" }}>
                                                {STYLE_BY_STATUS[job.status]?.label}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "10px",
                                                    maxWidth: "80px",
                                                    wordBreak: "break-word",
                                                    whiteSpace: "pre-wrap",
                                                    alignSelf: "center",
                                                    textAlign: "center"
                                                }}
                                            >
                                                {job.status_detail}
                                            </div>
                                        </td>
                                        <td style={{ padding: "4px", textAlign: "center" }}>
                                            <JobActionButton job={job}/>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

interface BackgroundJobAnimationStyleProps {
    inProgress: boolean
}

const BackgroundJobAnimationStyle = styled.div<BackgroundJobAnimationStyleProps>`
  .blink {
    ${({ inProgress }) => inProgress && `
        animation: blinker 1.5s linear infinite;
      `}
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`;

export default BackgroundJobPanel;
