import {action, observable} from 'mobx';
import {Skill} from '../model/Domain';


export class SkillStore {
    @observable
    skills: Skill[] = [];

    @observable
    selectedSkill?: Skill | null = null;

    @observable
    selectedSkillId?: number = undefined;

    @action
    setSkills(skills: Skill[]) {
        this.skills = skills;
    }

    @action
    setSelectedSkillId(skillId: number | undefined) {
        this.selectedSkillId = skillId;

        if (skillId && this.skills.length) {
            this.skills.forEach((skill) => {
                if (skill.id === skillId) {
                    this.selectedSkill = skill;
                    return false;
                }
            })
        }
    }

    @action
    select(skill: Skill | null) {
        this.selectedSkill = skill;
        this.selectedSkillId = skill?.id;
    }

    @action
    clear() {
        this.skills = [];
        this.selectedSkill = undefined;
        this.selectedSkillId = undefined;
    }
}